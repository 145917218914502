import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import Button from "components/common/Button";
import LanguagePicker from "components/LanguagePicker";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { ILifeChoiceTabs } from "types/context/lifeChoicePage";
import styles from "./index.module.scss";

const FirstScreen: FC = () => {
  const { tabHandler, lifeChoice, activeTab } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!language) {
    return null;
  }

  return (
    <section className={styles["first-screen"]}>
      <div className={`${styles["first-screen-container"]} | container`}>
        <img
          src={lifeChoice.coverImagePath}
          className={styles["cover-image"]}
        />
        <div className={styles["heading"]}>
          <div className={styles["navigation"]}>
            <Button.IconButton
              icon={<ArrowLeftIcon size={25} />}
              onClick={() => navigate(-1)}
            />{" "}
            <h1 className={styles["title"]}>nexme</h1>
          </div>
          <LanguagePicker
            className={styles["heading-lang-picker"]}
            itemType="secondry"
          />
        </div>
        <h1 className={styles["life-choice-title"]} lang={language} dir={dir}>
          {lifeChoice.title[language]}
        </h1>
        <ul
          role="tabs-list"
          className={styles["tabs-list"]}
          lang={language}
          dir={dir}>
          {Object.values(ILifeChoiceTabs).map((tab, idx) => (
            <li
              key={`--tab-${tab}-${idx}`}
              onClick={() => tabHandler(tab)}
              aria-selected={activeTab === tab}>
              <Button.OutlinedButton color="#fff">
                {t(tab)}
              </Button.OutlinedButton>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FirstScreen;
