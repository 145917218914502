import ClockIcon from "assets/icons/ClockIcon";
import LikeIcon from "assets/icons/LikeIcon";
import StudentHatIcon from "assets/icons/StudentHatIcon";
import BenefitsCard from "components/common/Card/BenefitsCard";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import { useModal } from "context/hooks/useModal";
import React, { FC, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import styles from "../index.module.scss";

const HighlightningBenefits: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { showModal } = useModal();
  const { language, dir } = useAppSelector(getSettings);

  const benefitCards = useMemo(
    () => [
      {
        icon: <StudentHatIcon size={20} />,
        title: lifeChoice?.majorOverview.certificateType.section[language],
        value: lifeChoice?.majorOverview.certificateType.initials,
        description: lifeChoice?.majorOverview.certificateType.description,
        modalTitle: lifeChoice?.majorOverview.certificateType.title,
      },
      {
        icon: <ClockIcon size={20} />,
        title: lifeChoice?.majorOverview.durationYears.section[language],
        value: lifeChoice?.majorOverview.durationYears.duration,
      },
      {
        icon: <LikeIcon size={20} />,
        title: lifeChoice?.majorOverview.successRate.section[language],
        value: `${lifeChoice?.majorOverview.successRate.rate}%`,
      },
    ],
    [lifeChoice]
  );

  if (!language) return null;

  return (
    <div className={`${styles["highlightning-benefits"]} | container`}>
      {benefitCards.map(
        ({ icon, title, value, description, modalTitle }, idx) => (
          <BenefitsCard
            key={`--benefit-card-${title}-${idx}`}
            icon={icon}
            title={title}
            value={value}
            onClick={() =>
              description &&
              showModal(
                <div>
                  <h3>{modalTitle}</h3>
                  <p dir={dir} lang={language}>
                    {description[language]}
                  </p>
                </div>,
                "sm"
              )
            }
          />
        )
      )}
    </div>
  );
};

export default HighlightningBenefits;
