import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      overview: "Overview",
      career: "Career",
      academy: "Academy",
      apply: "Apply",
      careerInfo: "Career info",
      jobFields: "Job fields",
      salleryDistribution: "Sallery Distribution",
      degreeType: 'Degree type',
      yearsStudy: 'Years Study',
      successRate: 'Success Rate',
      applyNow: 'Apply now',
      requiredSkills: 'Required Skills',
      technicalSkills: 'Technical Skills'
    },
  },
  he: {
    translation: {
      overview: "כללי",
      career: "קריירה",
      academy: "אקדמיה",
      apply: "הרשמה",
      careerInfo: "נתוני קריירה",
      jobFields: "תחומי עבודה",
      salleryDistribution: "התפלגות שכר",
      degreeType: 'סוג תעודה',
      yearsStudy: 'שנות לימוד',
      successRate: 'אחוזי הצלחה',
      applyNow: 'הירשם כעת',
      requiredSkills: 'כישורים מומלצים',
      technicalSkills: 'כישורים טכניים'
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
