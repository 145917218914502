import React, { FC } from "react";
import Carousel from "components/common/Carousel";
import { IBlockProps } from "types/components/block";
import styles from "./index.module.scss";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const Block: FC<IBlockProps> = ({
  block,
  carouselItemRender,
  carouselOptions,
}) => {
  const { language, dir } = useAppSelector(getSettings);
  
  return (
    <>
      <h1 className={styles["block__title"]} lang={language} dir={dir}>
        {block?.category && block?.category[language]}
      </h1>
      <Carousel
        renderItem={carouselItemRender}
        items={block.lifeChoices[0]}
        options={carouselOptions}
      />
    </>
  );
};

export default Block;
