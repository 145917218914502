/*eslint-disable*/
import CircleIcon from "assets/icons/CircleIcon";
import AccordionList from "components/common/AccordionList";
import Progress from "components/common/Progress";
import {
  RADIAN,
  pieChartColors,
  lifeChoiseDetailsDesktop,
} from "constantVariables";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IJobFiled } from "types/lifeChoice";
import { IRenderCustomizedLabel } from "types/utils/renderCustomizedLabel";
import { IRenderLegendProps } from "utils/renderLegend";
import styles from "./index.module.scss";

const JobFields: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  const { t } = useTranslation();

  const renderCustomizedLabel = useCallback(
    ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
    }: IRenderCustomizedLabel) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          className={styles["pie-chart-label-text"]}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    },
    []
  );

  const renderLegend = (props: IRenderLegendProps): React.ReactNode => {
    const { payload: payloadArr } = props;

    return (
      <ul role="list" className={styles["chart-legend-list"]}>
        {payloadArr.map(({ color, payload: { title } }, index) => (
          <li
            className={styles["chart-legend"]}
            key={`item-${index}`}
            dir={dir}
          >
            <CircleIcon fill={color} />
            <h5 lang={language}>{title[language]}</h5>
          </li>
        ))}
      </ul>
    );
  };

  const renderHeader = useCallback(
    ({ title, value }: IJobFiled) => (
      <>
        <h5 dir={dir} lang={language}>
          {title[language]}
        </h5>
        <Progress max={100} value={value} />
      </>
    ),
    [dir, language]
  );

  const renderBody = useCallback(
    ({ fileds }: IJobFiled) => (
      <ResponsiveContainer
        minHeight={250}
        minWidth={250}
        width="100%"
        height={250}
      >
        <PieChart height={250} width={250}>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            content={renderLegend as () => React.ReactNode}
            style={{
              top: 0,
            }}
          />
          <Pie
            dataKey="value"
            data={fileds}
            innerRadius={45}
            blendStroke
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {fileds.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={pieChartColors[index % pieChartColors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    ),
    [dir, language, lifeChoice]
  );

  return (
    <div className={styles["job-fields"]}>
      <h4
        className={!isDesktop ? "title-secondry" : "title-primary"}
        dir={dir}
        lang={language}
      >
        {lifeChoice.majorOverview.generalInfo.jobFields.section[language]}
      </h4>
      <AccordionList
        items={lifeChoice.majorOverview.generalInfo.jobFields.fields}
        renderHeader={renderHeader}
        renderBody={renderBody}
        headerClassName={styles["accordion-header-custom"]}
      />
    </div>
  );
};

export default JobFields;
