export const minMaxToArr = (
  min: number,
  max: number,
  intervalItemsCount?: number
): number[] => {
  if (!intervalItemsCount) intervalItemsCount = 1;

  const arr = [];

  for (
    let val: number = min;
    val <= max;
    val = val + (max - min) / intervalItemsCount
  ) {
    arr.push(val);
  }

  return arr;
};
