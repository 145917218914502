import Skeleton from "components/common/Skeleton";
import React, { FC } from "react";
import { IBlockCardSkeletonProps } from "types/components/common/blockCard";
import styles from "./index.module.scss";

const BlockCardSkeleton: FC<IBlockCardSkeletonProps> = ({ ariaCardSize }) => {
  return (
    <div
      className={styles["block-card-skeleton-wrapper"]}
      {...(ariaCardSize && { "aria-card-size": ariaCardSize })}>
      <Skeleton height="100%" />
    </div>
  );
};

export default BlockCardSkeleton;
