import React, { PropsWithChildren, useState } from "react";
import IconButton from "components/common/Button/IconButton";
import styles from "./index.module.scss";
import ChevronDownIcon from "assets/icons/ChevronDownIcon";
import ChevronUpIcon from "assets/icons/ChevronUpIcon";
import { IAccordionListProps } from "types/components/common/accordion";
import { getSettings } from "store/slices";
import { useAppSelector } from "store/hooks";

const AccordionList = <T,>({
  items,
  renderBody,
  renderHeader,
  headerClassName,
  wrapperClassName,
  hideDropdownbtn,
}: PropsWithChildren<IAccordionListProps<T>>) => {
  const [activeItem, setActive] = useState<number | null>(null);
  const { dir, language } = useAppSelector(getSettings);

  return (
    <div
      className={`${styles["accordion-list"]} ${wrapperClassName || ""}`}
      dir={dir}
      lang={language}>
      {items.map((item, idx) => (
        <div
          className={`${styles["accordion-item"]}`}
          key={`--accordion-item-${idx}`}>
          <div
            className={`${styles["accordion-header"]} ${headerClassName || ""}`}
            onClick={() => setActive(activeItem === idx ? null : idx)}>
            {renderHeader(item)}{" "}
            {!hideDropdownbtn && (
              <IconButton
                icon={
                  activeItem === idx ? (
                    <ChevronUpIcon fill="#5a70e8" />
                  ) : (
                    <ChevronDownIcon fill="#5a70e8" />
                  )
                }
              />
            )}
          </div>
          <div
            className={styles["accordion-body"]}
            aria-expanded={activeItem === idx}>
            {renderBody(item)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionList;
