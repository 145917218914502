import { useState } from "react";

export const useImage = () => {
  const [isError, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onError = () => {
    setError(true);
    setIsLoading(false);
  };
  
  const onLoad = () => setIsLoading(false);

  return {
    isLoading,
    isError,
    onError,
    onLoad,
  };
};
