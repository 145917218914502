import Block from "components/Block";
import BlockCard from "components/common/Card/BlockCard";
import BlockCardSkeleton from "components/common/Card/BlockCard/BlockCardSkeleton";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetLifeChoicesQuery } from "store/api/LifeChoiceBlocks";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IBlockChild } from "types/block";

const SuggestionsBlock = () => {
  const { dir, language } = useAppSelector(getSettings);
  const { lifeChoice } = useLifeChoicePageContext();

  const { data, isLoading, refetch } = useGetLifeChoicesQuery(
    {
      page: 1,
      pageSize: 1,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { pathname } = useLocation();

  useEffect(() => {
    refetch();
  }, [pathname]);

  const renderItem = useCallback(
    (block: IBlockChild) =>
      block ? (
        <BlockCard ariaCardSize="sm" item={block} />
      ) : (
        <BlockCardSkeleton ariaCardSize="sm" />
      ),
    []
  );

  if (!data?.lifeChoices || isLoading || !lifeChoice) return null;

  return (
    <div className={`suggestion | container`} dir={dir} lang={language}>
      <Block
        block={{
          ...data?.lifeChoices[0],
          category: lifeChoice?.translationTexts?.intrestedText,
        }}
        carouselItemRender={renderItem}
      />
    </div>
  );
};

export default SuggestionsBlock;
