import React, { FC } from "react";
import Carousel from "components/common/Carousel";
import Skeleton from "components/common/Skeleton";
import { IBlockSkeletonProps } from "types/components/block";
import { IBlockChild } from "types/block";
import styles from "./index.module.scss";

const BlockSkeleton: FC<IBlockSkeletonProps> = ({
  carouselItemRender,
  item,
}) => {
  return (
    <>
      <h1 className={styles["block__title"]}>
        <Skeleton />
      </h1>
      <Carousel
        items={item as IBlockChild[]}
        renderItem={carouselItemRender}
      />
    </>
  );
};

export default BlockSkeleton;
