import Image from "components/common/Image";
import { lifeChoiseDetailsDesktop } from "constantVariables";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC } from "react";
import { ITechnicalSkillModal } from "types/components/technicalSkillModal";
import styles from "./index.module.scss";

const TechnicalSkillModal: FC<ITechnicalSkillModal> = ({ skill }) => {
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop)

  return (
    <div className={styles["content"]}>
      <h3>{skill.title}</h3>
      <p>{skill.description}</p>
      <Image src={skill.imageUrl} skeletonOptions={{
        height: isDesktop ? '300px' : '200px'
      }} />
    </div>
  );
};

export default TechnicalSkillModal;
