import React, { createContext, useState, useContext } from "react";
export const UserAuthContext = createContext();

// eslint-disable-next-line react/prop-types
export default function UserAuth({ children }) {
  const [user, setUser] = useState(localStorage.getItem("token") ?? null);

  return (
    <UserAuthContext.Provider value={{ user, setUser }}>
      {children}
    </UserAuthContext.Provider>
  );
}
export function useUserAuth() {
  return useContext(UserAuthContext);
}
