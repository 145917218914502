/*eslint-disable*/
import React, { createContext, FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetLifeChoiceQuery } from "store/api/LifeChoiceBlocks";
import {
  ILifeChoicePageContext,
  ILifeChoiceTabsType,
  ILifeChoiceTabs,
  ILifeChoiceProviderProps,
} from "types/context/lifeChoicePage";

export const LifeChoicePageContext = createContext(
  {} as ILifeChoicePageContext
);

const LifeChoiceProvider: FC<ILifeChoiceProviderProps> = (props) => {
  const [activeTab, setTab] = useState<ILifeChoiceTabsType>(
    ILifeChoiceTabs.OVERVIEW
  );

  const tabHandler = useCallback(
    (tab: ILifeChoiceTabsType) => {
      setTab(tab);
    },
    [setTab, activeTab]
  );

  const { id } = useParams();
  const { data: lifeChoice } = useGetLifeChoiceQuery({ id: id! });

  if (!lifeChoice) return null;

  return (
    <LifeChoicePageContext.Provider
      value={{ activeTab, tabHandler, lifeChoice }}
      {...props}
    />
  );
};

export default LifeChoiceProvider;
