import ReviewCard from "components/common/Card/ReviewCard";
import Carousel from "components/common/Carousel";
import { lifeChoiseDetailsDesktop } from "constantVariables";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import useMediaQuery from "hooks/useMediaQuery";
import React, { useCallback, useMemo } from "react";
import { IGraduateReview } from "types/lifeChoice";
import styles from "../index.module.scss";

const Comments = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);

  const carouselOptions = useMemo(
    () =>
      isDesktop
        ? {
            slidesPerView: 2,
            pagination: { clickable: true },
          }
        : { pagination: { clickable: true } },
    [isDesktop]
  );

  const renderItem = useCallback(
    (item: IGraduateReview) => (
      <ReviewCard
        cardType="overviewCard"
        item={item}
        itemType={!isDesktop ? "secondry" : "primary"}
      />
    ),
    [isDesktop]
  );

  return (
    <div
      className={`${styles["comments"]} | ${
        !isDesktop && "container-right-aligned"
      }`}
    >
      <Carousel
        items={lifeChoice.majorOverview.graduateReviews}
        renderItem={renderItem}
        options={carouselOptions}
      />
    </div>
  );
};

export default Comments;
