import Footer from "components/Footer";
import Header from "components/Header";
import ModalProvider from "context/modal";
import useScroll from "hooks/useScroll";
import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IAppLayoutProps } from "types/components/appLayout";

const AppLayout: FC<IAppLayoutProps> = ({ children }) => {
  const { isLoading } = useAppSelector(getSettings);
  useScroll()

  if (isLoading) {
    return null;
  }

  return (
    <ModalProvider>
      <Header />
      <main className="padding-block-start-900">{children}</main>
      <Footer />
    </ModalProvider>
  );
};

export default AppLayout;
