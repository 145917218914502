import React, { FC } from "react";
import { IButtonProps } from "types/components/common/btn";
import styles from "./index.module.scss";

const OutlinedButton: FC<IButtonProps> = ({
  color,
  children,
  className,
  ...rest
}) => {
  return (
    <button
      className={`${styles["outlined-btn"]} ${className || ""}`}
      style={{ color: color || "#000" }}
      {...rest}>
      {children}
    </button>
  );
};

export default OutlinedButton;
