import { useEffect, useRef } from "react";

export const useOutsideClick = <T extends HTMLElement>(cb: () => void) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!ref.current) return

      if (!ref.current.contains(e.target as Node) || ref.current === e.target) cb();
    };

    window.addEventListener("mousedown", handler);

    return () => window.removeEventListener("mousedown", handler);
  }, [ref.current]);

  return {
    ref,
  };
};
