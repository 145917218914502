import React, { FC, useMemo } from "react";
import { IProgressProps } from "types/components/common/progress";
import styles from "./index.module.scss";

const Progress: FC<IProgressProps> = ({ max, value, className }) => {
  const width = useMemo(() => `${(value / max) * 100}%`, [max, value]);

  return (
    <div
      className={`${styles["progress"]} ${className || ""}`}
      style={{ "--width": width } as any}
    />
  );
};

export default Progress;
