import React, { useCallback } from "react";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import {
  ILifeChoiceTabs,
  ILifeChoiceTabsType,
} from "types/context/lifeChoicePage";
import Overview from "./Overview";
import Career from "./Career";
import Academy from "./Academy";
import Apply from "./Apply";

const Content = () => {
  const { activeTab } = useLifeChoicePageContext();

  const renderTab = useCallback(
    (tab: ILifeChoiceTabsType) => {
      switch (tab) {
        case ILifeChoiceTabs.OVERVIEW:
          return <Overview />;
        case ILifeChoiceTabs.CAREER:
          return <Career />;
        case ILifeChoiceTabs.ACADEMY:
          return <Academy />;
        case ILifeChoiceTabs.APPLY:
          return <Apply />;
      }
    },
    [activeTab]
  );

  return <main className="padding-block-200">{renderTab(activeTab)}</main>;
};

export default Content;
