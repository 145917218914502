import Image from "components/common/Image";
import React, { FC } from "react";
import styles from "./index.module.scss";

const ImageCard: FC<any> = ({ src }) => {
  return (
    <div className={styles["image-card"]}>
      <Image src={src} />
    </div>
  );
};

export default ImageCard;
