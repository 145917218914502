import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
  ICarouselOptions,
  ICarouselProps,
  ISliderBtnsProps,
} from "types/components/common/carousel";
import IconButton from "../Button/IconButton";
import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";
import ChevronRightIcon from "assets/icons/ChevronRightIcon";
import useMediaQuery from "hooks/useMediaQuery";
import { lifeChoiseDetailsDesktop } from "constantVariables";
import styles from "./index.module.scss";
import { useAppSelector } from "store/hooks";
import { getCareerTabData, getSettings } from "store/slices";

const SlideBtns: FC<ISliderBtnsProps> = ({ isRtl }) => {
  const swiper = useSwiper();

  return (
    <div className={styles["carousel-btns"]}>
      {isRtl ? (
        <>
          <IconButton
            icon={<ChevronRightIcon size={30} />}
            className={styles["carousel-btn"]}
            onClick={() => swiper.slidePrev()}
          />
          <IconButton
            icon={<ChevronLeftIcon size={30} />}
            className={styles["carousel-btn"]}
            onClick={() => swiper.slideNext()}
          />
        </>
      ) : (
        <>
          <IconButton
            icon={<ChevronLeftIcon size={30} />}
            className={styles["carousel-btn"]}
            onClick={() => swiper.slidePrev()}
          />
          <IconButton
            icon={<ChevronRightIcon size={30} />}
            className={styles["carousel-btn"]}
            onClick={() => swiper.slideNext()}
          />
        </>
      )}
    </div>
  );
};

const SwiperDailyScheduleButtons: FC<ISliderBtnsProps> = () => {
  const swiper = useSwiper();

  swiper.on("slideChange", function () {
    setIsActiveIndex(swiper.realIndex);
  });

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const swiperSlideTo = (index: number) => {
    swiper.slideTo(index);
  };

  const { language, dir } = useAppSelector(getSettings);
  const { activeCareer } = useAppSelector(getCareerTabData);
  let weekDays = [];
  if (activeCareer?.calendar) {
    const calendar = Object.values(activeCareer.calendar);
    weekDays = calendar.map((item) => item.inital);
  }

  return (
    <div
      className={`${styles["week-days-wrapper"]} ${
        dir === "rtl" ? styles["week-days-wrapper-rtl"] : ""
      }`}
    >
      {weekDays.map((day, idx) => (
        <p
          key={`--day-btn-${day}-${idx}`}
          onClick={() => swiperSlideTo(idx + 1)}
          className={`${isActiveIndex === idx ? styles["active-btn"] : ""}`}
        >
          {day[language]}
        </p>
      ))}
    </div>
  );
};

const Carousel = <T,>({
  renderItem,
  items,
  options,
  sliderDailyScheduleBtns,
  idx: carouselIdx,
  onSlideChange,
}: PropsWithChildren<ICarouselProps<T>>) => {
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  const { dir } = useAppSelector(getSettings);

  const {
    loop,
    slideWidth,
    slidesPerView,
    centeredSlides,
    slideToClickedSlide,
    sliderBtns,
    pagination,
  }: ICarouselOptions = useMemo(() => {
    return {
      slideWidth: "auto",
      slidesPerView: "auto",
      loop: true,
      sliderBtns: false,
      ...options,
    };
  }, [options]);

  if (!items) return null;

  return (
    <Swiper
      onSlideChange={onSlideChange}
      modules={[A11y, Pagination]}
      slidesPerView={slidesPerView}
      centeredSlides={centeredSlides}
      slideToClickedSlide={slideToClickedSlide}
      loop={loop}
      allowSlideNext
      spaceBetween={15}
      dir={dir}
      pagination={pagination}
      key={dir}
    >
      {sliderDailyScheduleBtns && (
        <SwiperDailyScheduleButtons isRtl={dir === "rtl"} />
      )}
      {items.map((slide, idx) => (
        <SwiperSlide
          key={`--carousel-slide-${idx}`}
          style={{ width: slideWidth }}
        >
          {renderItem(slide, carouselIdx)}
        </SwiperSlide>
      ))}
      {sliderBtns && isDesktop && <SlideBtns isRtl={dir === "rtl"} />}
    </Swiper>
  );
};

export default Carousel;
