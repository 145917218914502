import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IGetLifeChoiceParams,
  IGetLifeChoicesParams,
  IGetCareerParams
} from "types/api/lifeChoices";
import { IBlocksResponse } from "types/block";
import { ILifeChoice,ICareer } from "types/lifeChoice";

export const lifeChoiseBlocksApi = createApi({
  reducerPath: "lifeChoiseBlocksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}api/v1`,
    prepareHeaders: (headers) => {
      headers.set("tocken", "q8np39yq849356voq45vo47856voq6b5oqb45v6oqb65");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLifeChoices: builder.query<IBlocksResponse, IGetLifeChoicesParams>({
      query: ({ pageSize, page }) =>
        `/life_choices?page=${page}&pageSize=${pageSize}`,
    }),
    getLifeChoice: builder.query<ILifeChoice, IGetLifeChoiceParams>({
      query: ({ id }) => `/life_choices/${id}`,
    }),
    getCareer: builder.query<ICareer, IGetCareerParams>({
      query: ({ id }) => `/career/${id}`,
    }),
  }),
});

export const { useGetLifeChoiceQuery, useGetLifeChoicesQuery, useGetCareerQuery } =
  lifeChoiseBlocksApi;
