import React, { FC } from "react";
import { IIconButtonProps } from "types/components/common/iconBtn";
import styles from "./index.module.scss";

const IconButton: FC<IIconButtonProps> = ({ icon, className, ...rest }) => {
  if (!icon) return null;

  return (
    <button {...rest} className={`${styles["icon-btn"]} ${className || ""}`}>
      {icon}
    </button>
  );
};

export default IconButton;
