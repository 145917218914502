import ReviewCard from "components/common/Card/ReviewCard";
import Carousel from "components/common/Carousel";
import { lifeChoiseDetailsDesktop } from "constantVariables";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC, useMemo } from "react";
import { ICommentsProps } from "types/components/comments";
import { IGraduateReview } from "types/lifeChoice";
import styles from "../index.module.scss";

const Comments: FC<ICommentsProps> = ({ items, title }) => {
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  const carouselOptions = useMemo(
    () =>
      isDesktop
        ? {
            slidesPerView: items.length >= 4 ? 4 : items.length,
          }
        : {},
    [isDesktop]
  );

  const renderItem = (item: IGraduateReview) => (
    <ReviewCard
      className="career-review-card"
      cardType="careerCard"
      title={title}
      item={item}
      itemType={!isDesktop ? "secondry" : "primary"}
    />
  );

  return (
    <div
      className={`${styles["comments"]} | ${
        !isDesktop && "container-right-aligned"
      }`}
    >
      <Carousel
        items={items}
        renderItem={renderItem}
        options={carouselOptions}
      />
    </div>
  );
};

export default Comments;
