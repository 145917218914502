import { useModal } from "context/hooks/useModal";
import { useOutsideClick } from "hooks/useOutsideClick";
import React, { FC, useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IDialogProps } from "types/components/common/modal";
import styles from "./index.module.scss";

const Modal: FC<IDialogProps> = ({ children }) => {
  const { open, hideModal, modalSize } = useModal();
  const { ref } = useOutsideClick<HTMLDialogElement>(hideModal);
  const { dir } = useAppSelector(getSettings);

  useEffect(() => {
    const body = document.documentElement;

    if (open) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [open]);

  /*eslint-disable*/
  return (
    <dialog
      ref={ref}
      className={styles["modal"]}
      open={open}
      aria-modal-size={modalSize}
      dir={dir}>
      <div className={styles["modal-container"]}>{children}</div>
      <div className={styles["backdrop"]} />
    </dialog>
  );
};

export default Modal;
