import React, { FC, useCallback, useEffect, useState } from "react";
import { IBlock, IBlockChild } from "types/block";
import BlockCard from "components/common/Card/BlockCard";
import styles from "./index.module.scss";
import Block from "components/Block";
import BlockSkeleton from "components/Block/BlockSkeleton";
import BlockCardSkeleton from "components/common/Card/BlockCard/BlockCardSkeleton";
import { emptyBlocks } from "mocks/emptyBlocks";
import { useGetLifeChoicesQuery } from "store/api/LifeChoiceBlocks";
import { Waypoint } from "react-waypoint";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const pageSize = 3;

const Blocks: FC = () => {
  const [page, setPage] = useState<number>(1);

  const [blocks, setBlocks] = useState<IBlock[]>([]);

  const { data } = useGetLifeChoicesQuery({ page, pageSize });
  const { dir, language } = useAppSelector(getSettings);

  useEffect(() => {
    if (data?.lifeChoices) {
      setBlocks((prev) => prev.concat(data.lifeChoices));
    }
  }, [data]);

  const renderItem = useCallback(
    (block: IBlockChild) =>
      block ? <BlockCard item={block} /> : <BlockCardSkeleton />,
    []
  );

  return (
    <div
      className={`${styles["blocks-wrapper"]} | container-right-aligned`}
      dir={dir}
      lang={language}>
      <ul role="list" className={styles["blocks-section"]}>
        {data?.lifeChoices
          ? blocks.map((block, idx) => (
              <li key={block.category + `-${idx}`}>
                <Waypoint
                  onEnter={() =>
                    blocks.length - 1 === idx && setPage(page + 1)
                  }>
                  <div style={{ marginBottom: "15px" }}>
                    <Block block={block} carouselItemRender={renderItem} />
                  </div>
                </Waypoint>
                {blocks.length - 1 === idx && (
                  <BlockSkeleton
                    item={emptyBlocks[0]}
                    carouselItemRender={renderItem}
                  />
                )}
              </li>
            ))
          : emptyBlocks.map((emptyBlock, idx) => (
              <li key={`--empty-block-${idx}`}>
                <BlockSkeleton
                  carouselItemRender={renderItem}
                  item={emptyBlock}
                />
              </li>
            ))}
      </ul>
    </div>
  );
};

export default Blocks;
