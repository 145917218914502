export const RADIAN = Math.PI / 180;

export const primary400 = "#1a4e99";
export const primary300 = "#5a70e8";
export const primary100 = "#dee2fb";

export const accent400 = "#f29424";

export const neutral100 = "#fff";
export const neutral300 = "#c4c4c4";

export const pieChartColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const lifeChoiseDetailsMobile = "(min-width: 300px)";
export const lifeChoiseDetailsTablet = "(min-width: 550px)";
export const lifeChoiseDetailsDesktop = "(min-width: 998px)";

export const ratingColors = {
  primary: {
    fillColor: primary300,
    emptyColor: primary100,
  },
  secondry: {
    fillColor: neutral100,
    emptyColor: neutral300,
  },
};
