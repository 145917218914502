import FireIcon from "assets/icons/FireIcon";
import StarIcon from "assets/icons/StarIcon";
import SuccessIcon from "assets/icons/SuccessIcon";
import React, { FC, useCallback, useMemo } from "react";
import { IHashTagsProps } from "types/components/hastags";
import styles from "./index.module.scss";

const Hashtags: FC<IHashTagsProps> = ({ hashtags, className }) => {
  const iconsArr = useMemo(
    () => [
      {
        name: "hot",
        icon: <FireIcon fill="red" />,
      },
      {
        name: "top",
        icon: <StarIcon fill="orange" />,
      },
      {
        name: "success",
        icon: <SuccessIcon fill="green" />,
      },
    ],
    []
  );

  const renderIcon = useCallback((name: string) => {
    return iconsArr.find((icon) => name.toLowerCase().includes(icon.name))
      ?.icon;
  }, []);

  const renderHashtags = useCallback((arr: string[]) => {
    return arr.map((item) => (
      <div key={item} className={styles["hashtag"]}>
        <>
          {renderIcon(item)} <p>{item}</p>
        </>
      </div>
    ));
  }, []);

  return (
    <div className={`${styles["hashtags"]} ${className || ""} | container`}>
      {renderHashtags(hashtags)}
    </div>
  );
};

export default Hashtags;
