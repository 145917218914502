import React, { FC, useState } from "react";
import ToggleIcon from "assets/icons/ToggleIcon";
import IconButton from "components/common/Button/IconButton";
import styles from "./index.module.scss";
import SearchInput from "components/common/Input/Search";
import SearchIcon from "assets/icons/SearchIcon";
import LanguagePicker from "components/LanguagePicker";
import Image from "components/common/Image";
import { useGetLifeChoicesQuery } from "store/api/LifeChoiceBlocks";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const Header: FC = () => {
  const [navActive, setNavActive] = useState(false);
  const { language } = useAppSelector(getSettings);

  const toggleNav = () => setNavActive(!navActive);
  const { data } = useGetLifeChoicesQuery(
    {
      page: 1,
      pageSize: 1,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const navigation = [
    {
      id: "--nav-questionaries",
      title: data?.questionnaireTerm,
      href: "/questionaries",
    },
    {
      id: "--nav-my-account",
      title: data?.myAccountTerm,
      href: "/my-account",
    },
    {
      id: "--nav-my-reports",
      title: data?.myReportsTerm,
      href: "/my-reports",
    },
    {
      id: "--nav-help",
      title: data?.helpTerm,
      href: "/help",
    },
    {
      id: "--nav-contact",
      title: data?.contactTerm,
      href: "/contact",
    },
  ];

  return (
    <header className={`${styles["header-wrapper"]}`}>
      <div className={`${styles["header-container"]}`}>
        <a href="/" className={styles["logo-link"]}>
          <Image
            src="http://x-ingle.com/assets/images/logo_nexme_app.png"
            alt="logo"
            className={styles["header-logo"]}
            skeletonOptions={{ width: "200px", height: "50px" }}
          />
        </a>
        <nav className={styles["primary-navigation"]} data-visible={navActive}>
          <ul role="list" className={styles["nav-list"]}>
            {navigation.map(({ href, id, title }, idx) => (
              <li key={id + `-${idx}`} className="list-item">
                <a href={href} className={styles["nav-link"]}>
                  {title && title[language]}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <LanguagePicker />
        <IconButton
          onClick={toggleNav}
          className={styles["nav-toggler"]}
          icon={<ToggleIcon />}
        />
        <SearchInput
          placeholder={data?.searchTerm?.[language]}
          icon={<SearchIcon fill="orange" size={20} />}
          inputContainerClassName={styles["search-input-header"]}
        />
      </div>
    </header>
  );
};

export default Header;
