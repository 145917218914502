import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "context/authProvider";

const Login: FC = () => {
  const { setUser } = useUserAuth();
  const [inputVales, setInputValues] = React.useState({
    email: "",
    password: "",
    error: "",
    loading: false,
  });

  function handleSubmit() {
    setInputValues({ ...inputVales, loading: true });
    fetch(process.env.REACT_APP_SERVER_URL + "api/v1/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputVales.email,
        password: inputVales.password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setInputValues({ ...inputVales, loading: false });
        if (data.status === "OK") {
          setInputValues({ ...inputVales, error: "" });
          setUser(data.data.tocken);
          localStorage.setItem("token", data.data.tocken);
        } else {
          setInputValues({ ...inputVales, error: data.data.message });
        }
      })
      .catch(() => {
        setInputValues({
          ...inputVales,
          error: "Something went wrong!",
          loading: false,
        });
      });
  }

  return (
    <>
      {inputVales.loading && (
        <div className="h-screen w-screen flex items-center justify-center bg-black bg-opacity-40 z-10">
          <p className="text-white font-bold text-2xl">Loading...</p>
        </div>
      )}
      <div className="h-screen w-screen flex items-center justify-center bg-white">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2 max-w-md w-full">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Email"
              value={inputVales.email}
              onChange={(e) =>
                setInputValues({ ...inputVales, email: e.target.value })
              }
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="******************"
              value={inputVales.password}
              onChange={(e) =>
                setInputValues({ ...inputVales, password: e.target.value })
              }
            />
            {inputVales.error && (
              <p className="text-red-500 text-xs italic">
                {inputVales.error ?? "Something went wrong!"}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleSubmit}
            >
              Sign In
            </button>
            <Link
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              to="/login"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
