import { useImage } from "hooks/useImage";
import React, { FC } from "react";
import { IImageProps } from "types/components/common/image";
import Skeleton from "components/common/Skeleton";

const Image: FC<IImageProps> = ({ errorMessage, skeletonOptions, ...rest }) => {
  const { isError, isLoading, onError, onLoad } = useImage();

  return (
    <>
      <img
        {...rest}
        onError={onError}
        onLoad={onLoad}
        style={{ opacity: isError ? 0 : 1 }}
      />
      {isLoading && (
        <Skeleton width="100%" height="100%" {...skeletonOptions} />
      )}
      {errorMessage && isError && <h5>{errorMessage}</h5>}
    </>
  );
};

export default Image;
