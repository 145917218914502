import React, { FC, useCallback, useState } from "react";
import styles from "./index.module.scss";
import DefaultCard from "components/common/Card/DefaultCard";
import StudentHatIcon from "assets/icons/StudentHatIcon";
import { primary400 } from "constantVariables";
import { getSettings } from "store/slices";
import { useAppSelector } from "store/hooks";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import { NavLink } from "react-router-dom";

const CourseList: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { dir, language } = useAppSelector(getSettings);
  const { lifeChoice } = useLifeChoicePageContext();

  const renderTab = useCallback(
    (tab: number) => {
      if (!lifeChoice.academy.courseList[tab]) return [];

      return lifeChoice.academy.courseList[tab].map(
        ({ credit, id, title }, idx) => (
          <NavLink
            to={`/year${tab + 1}/course${idx + 1}`}
            key={`--tab-${tab}-item${id}-${idx}`}
          >
            <DefaultCard className={styles["list-card"]}>
              <h4>{title[language].toUpperCase()}</h4>
              <StudentHatIcon fill={primary400} size={25} />
              <h4>{credit} pt</h4>
            </DefaultCard>
          </NavLink>
        )
      );
    },
    [dir, language]
  );

  return (
    <div
      className={`${styles["course-list"]} | container`}
      dir={dir}
      lang={language}
    >
      <div className={styles["heading"]}>
        <h4 className={`fw-600 text-primary-400`} dir={dir}>
          {lifeChoice?.translationTexts?.courseListText?.[language]}
        </h4>
        <div className={styles["course-list-btns"]}>
          {lifeChoice?.academy?.courseList?.map((_, idx) => (
            <button
              dir={dir}
              className={styles["course-list-btn"]}
              key={idx}
              onClick={() => setActiveTab(idx)}
              {...(activeTab === idx && { "aria-expanded": true })}
            >
              <h2>Y{idx + 1}</h2>
            </button>
          ))}
        </div>
      </div>
      <div className={styles["cards-list"]}>{renderTab(activeTab)}</div>
    </div>
  );
};

export default CourseList;
