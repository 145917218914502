import { lifeChoiseDetailsDesktop } from "constantVariables";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC, useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { setActiveCareersSliders } from "store/slices";
import CareerDesktop from "../Career/Desktop";
import CareerMobile from "../Career/Mobile";

const Career: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return ()=> {
      dispatch(setActiveCareersSliders(null))
    }
  })
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);

  return <>{!isDesktop ? <CareerMobile /> : <CareerDesktop />}</>;
};

export default Career;
