import DefaultCard from "components/common/Card/DefaultCard";
import React, { FC } from "react";
import styles from "./index.module.scss";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const CareerInfoContent: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { language } = useAppSelector(getSettings);

  return (
    <div
      className={`${styles["career-info-content"]} | grid`}
      style={{ "--cols-mobile": 2, "--cols": 4 } as any}>
      <DefaultCard className={styles['info-card']}>
        <h5>{lifeChoice.majorOverview.generalInfo.professionGrowth.section[language]}</h5>
        <h1>{lifeChoice.majorOverview.generalInfo.professionGrowth.rate}</h1>
      </DefaultCard>
      <DefaultCard className={styles['info-card']}>
        <h5>{lifeChoice.majorOverview.generalInfo.annualOpenings.section[language]}</h5>
        <h1>{lifeChoice.majorOverview.generalInfo.annualOpenings.rate}</h1>
      </DefaultCard>
      <DefaultCard className={styles['info-card']}>
        <h5>{lifeChoice.majorOverview.generalInfo.yearlyGraduated.section[language]}</h5>
        <h1>{lifeChoice.majorOverview.generalInfo.yearlyGraduated.rate}</h1>
      </DefaultCard>
      <DefaultCard className={styles['info-card']}>
        <h5>{lifeChoice.majorOverview.generalInfo.juniorOpportunities.section[language]}</h5>
        <h1>{lifeChoice.majorOverview.generalInfo.juniorOpportunities.rate}</h1>
      </DefaultCard>
    </div>
  );
};

export default CareerInfoContent;
