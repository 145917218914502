import React, { FC } from "react";
import styles from './index.module.scss'
import ApplyNow from "components/ApplyNow";
import SuggestionsBlock from "components/SuggestionsBlock";
import QnA from "../Q&A";
import CourseList from "../CourseList";
import FacultyPhotos from "../FacultyPhotos";

const AcademyMobile: FC = () => {
  return (
    <section className={`${styles['academy-wrapper']}`}>
      <CourseList/>
      <FacultyPhotos/>
      <QnA />
      <ApplyNow/>
      <SuggestionsBlock/>
    </section>
  );
};

export default AcademyMobile;
