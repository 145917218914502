import React, { FC } from "react";
import styles from "./index.module.scss";
import { IOccupationCardProps } from "types/components/common/occupationCard";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import ClockIcon from "assets/icons/ClockIcon";

const OccupationCard: FC<IOccupationCardProps> = ({
  item,
  className,
  onClick,
  showDescription,
  subCarousel,
  isOrange,
}) => {
  const { dir, language } = useAppSelector(getSettings);
  return (
    <div className={styles["occupation-card-wrapper"]} onClick={onClick}>
      <div
        className={`${
          isOrange
            ? styles["occupation-card-orange"]
            : subCarousel
            ? styles["occupation-card-blue"]
            : styles["occupation-card"]
        } ${className}`}
        dir={dir}
        lang={language}
      >
        {item.discripetion && showDescription ? (
          <div className={styles["occupation-card-with-descr"]}>
            <p className={styles["occupation-card-title"]}>
              {item.title[language]}
            </p>
            <p className={styles["occupation-card-description"]}>
              {item.discripetion[language]}
            </p>
            <div className={styles["occupation-card-info"]}>
              <p className={styles["occupation-card-row"]}>
                <span className={styles["occupation-card-icon"]}>
                  <ClockIcon size={15} />
                </span>
                <span>{item.avgTime}</span>
              </p>
              <p className={styles["occupation-card-row"]}>
                <span className={styles["occupation-card-icon"]}>$</span>
                <span>{item.avgSalary}</span>
              </p>
            </div>
          </div>
        ) : (
          <div>{item.title[language]}</div>
        )}
      </div>
    </div>
  );
};

export default OccupationCard;
