import DefaultCard from "components/common/Card/DefaultCard";
import { lifeChoiseDetailsDesktop } from "constantVariables";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC, useMemo } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { minMaxToArr } from "utils/minMaxToArr";
import { nFormatter } from "utils/nFormatter";
import { numArrToChart } from "utils/numArrToChart";
import styles from "./index.module.scss";

const SalleryDistribution: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  const { dir, language } = useAppSelector(getSettings);

  const chartData = useMemo(() => {
    const salleryDistribution =
      lifeChoice.majorOverview.generalInfo.salleryDistribution;

    const { min, max } = salleryDistribution;

    const arr = minMaxToArr(min, max, 4);

    return !isDesktop ? numArrToChart(arr, 1.2) : numArrToChart(arr, 3.2);
  }, [lifeChoice]);

  return (
    <div className={styles["sallery-distribution"]}>
      <h4
        className={!isDesktop ? "title-secondry" : "title-primary"}
        dir={dir}
        lang={language}
      >
        {
          lifeChoice.majorOverview.generalInfo.salleryDistribution.section[
            language
          ]
        }
      </h4>
      <DefaultCard>
        <ResponsiveContainer width="100%" height={!isDesktop ? 200 : 250}>
          <LineChart data={chartData} margin={{ right: 20, left: 20 }}>
            <defs>
              <linearGradient
                x1="0"
                y1="0.5"
                x2="1"
                y2="0.5"
                id="stroke-gradient"
              >
                <stop offset="0%" stopColor="#f7fafc" />
                <stop offset="12.5%" stopColor="#c6dbee" />
                <stop offset="25%" stopColor="#97bbe7" />
                <stop offset="50%" stopColor="#5a70e8" />
                <stop offset="62.5%" stopColor="#6f98e5" />
                <stop offset="75%" stopColor="#97bbe7" />
                <stop offset="100%" stopColor="#f7fafc" />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="val2"
              tickFormatter={(tick: number) => nFormatter(tick)}
              tickLine={false}
              type="number"
              ticks={chartData.map((item) => item.val2)}
              domain={["dataMin", "dataMax"]}
            />
            <YAxis
              domain={["dataMin", "dataMax"]}
              tickLine={false}
              width={0}
              interval={0}
              ticks={
                !isDesktop
                  ? Array.from({ length: 3 }, (_, x) => x + 1)
                  : Array.from({ length: 8 }, (_, x) => x + 1)
              }
            />
            <CartesianGrid strokeWidth={1} vertical={false} stroke="#c6dbee" />
            <Line
              type="monotone"
              dataKey="val1"
              stroke="url(#stroke-gradient)"
              strokeWidth={3}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </DefaultCard>
    </div>
  );
};

export default SalleryDistribution;
