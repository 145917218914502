import React, { FC } from "react";
import { ICareerInfoProps } from "types/components/careerInfo";
import ApplyNow from "components/ApplyNow";
import styles from "./index.module.scss";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";

const CareerInfo: FC<ICareerInfoProps> = ({ children }) => {
  const { dir, language } = useAppSelector(getSettings);
  const { lifeChoice } = useLifeChoicePageContext();

  return (
    <div className={styles["career-info"]} dir={dir} lang={language}>
      <h2 className={`${styles["career-info-title"]} | text-white`}>
        {lifeChoice.majorOverview.generalInfo.section[language]}
      </h2>
      <div className={`${styles["career-info-container"]} | container`}>
        {children}
      </div>
      <ApplyNow />
    </div>
  );
};

export default CareerInfo;
