import OutlinedButton from "components/common/Button/OutlinedButton";
import { neutral100, primary400 } from "constantVariables";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getSettings, setLanguage } from "store/slices";
import { ILanguagePicker } from "types/components/languagePicker";
import { ILangItem } from "types/store/settings";
import styles from "./index.module.scss";

const LanguagePicker: FC<ILanguagePicker> = ({ className, itemType }) => {
  const { language, languages } = useAppSelector(getSettings);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const languageHandler = ({ lang, dir }: ILangItem) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage({ lang, dir }));
  };

  return (
    <ul role="list" className={`${styles["language-list"]} ${className || ""}`}>
      {languages.map(({ lang, dir }, idx) => (
        <OutlinedButton
          key={`--lang-${lang}-${idx}`}
          className={`${styles["language-btn"]} ${
            styles[itemType || "primary"]
          }`}
          color={itemType === "secondry" ? neutral100 : primary400}
          onClick={() => languageHandler({ lang, dir })}
          {...(lang === language && { "aria-checked": true })}>
          <span lang={lang} dir={dir}>
            {lang}
          </span>
        </OutlinedButton>
      ))}
    </ul>
  );
};

export default LanguagePicker;
