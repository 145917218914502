import React, { FC } from "react";
import AcademyDesktop from "./Desktop";
import AcademyMobile from "./Mobile";
import useMediaQuery from "hooks/useMediaQuery";
import { lifeChoiseDetailsDesktop } from "constantVariables";

const Academy: FC = () => {
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);

  return <>{isDesktop ? <AcademyDesktop /> : <AcademyMobile />}</>;
};

export default Academy;
