import LifeChoiceProvider from "context/lifeChoice";
import { useLocaleLanguage } from "hooks/useLocaleLanguage";
import Block from "pages/Block";
import Home from "pages/Home";
import Course from "pages/Course";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { useUserAuth } from "context/authProvider";
import Login from "components/Login";
import { Navigate } from "react-router-dom";

function App() {
  const { user } = useUserAuth();
  /*eslint-disable-next-line*/
  const { isLoading } = useLocaleLanguage();

  return (
    <Routes>
      <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
      <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
      <Route
        path="/:id"
        element={
          user ? (
            <LifeChoiceProvider>
              <Block />
            </LifeChoiceProvider>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/year:id/course:id"
        element={user ? <Course /> : <Navigate to="/login" />}
      ></Route>
      <Route
        path="*"
        element={user ? <h1>Not Found</h1> : <Navigate to="/login" />}
      />
    </Routes>
  );
}

export default App;
