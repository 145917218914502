/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from "react";

import { IScheduleCardProps } from "types/components/common/scheduleCard";

import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import styles from "./index.module.scss";

const ScheduleCard: FC<IScheduleCardProps> = ({
  className,
  item,
}) => {

  const { dir, language } = useAppSelector(getSettings);

  return (
    <div className={styles["schedule-card"]}>
      {item.map((scheduleItem, index) => (
        <div className={styles["schedule-card-item"]} key={index}>
          <p>{scheduleItem.time}</p>
          <div className={styles["schedule-card-item__title"]}>{scheduleItem.title[language]}</div>
          <div className={styles["schedule-card-item__type"]}>{scheduleItem.type}</div>
        </div>
      ))}
    </div>
  );
};
export default ScheduleCard;
