import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getSettings, setLanguage } from "store/slices";

export const useLocaleLanguage = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { isLoading } = useAppSelector(getSettings);

  useEffect(() => {
    // dispatch(setLoading(true));
    // fetch("https://ipapi.co/json")
    //   .then((res) => res.json())
    //   .then((response) => {
    //     let lang, dir;
    //     if (response.country === "IL") {
    //       lang = "he";
    //       dir = "rtl";
    //     } else {
    const lang = "en";
    const dir = "ltr";
    // }

    i18n.changeLanguage(lang);
    dispatch(setLanguage({ lang, dir }));
    // })
    // .catch((e) => {
    //   /*eslint-disable-next-line*/
    //   console.log(e);
    // })
    // .finally(() => dispatch(setLoading(false)));
  }, []);

  return {
    isLoading,
  };
};
