import React, { FC } from "react";
import { IBenefitsCardProps } from "types/components/common/benefitsCard";
import styles from "./index.module.scss";

const BenefitsCard: FC<IBenefitsCardProps> = ({ icon, title, value, ...rest }) => {
  return (
    <div className={styles["benefits-card"]} {...rest}>
      <div className={styles["benefits-card-icon"]}>{icon}</div>
      <div className={styles["benefits-card-title"]}>{title}</div>
      <div className={styles["benefits-card-value"]}>{value}</div>
    </div>
  );
};

export default BenefitsCard;
