import Footer from "components/Footer";
import ModalProvider from "context/modal";
import useScroll from "hooks/useScroll";
import React, { FC } from "react";
import Content from "./Content";
import FirstScreen from "./FirstScreen";

const Block: FC = () => {
  useScroll()

  return (
    <ModalProvider>
      <FirstScreen />
      <Content />
      <Footer/>
    </ModalProvider>
  );
};

export default Block;
