import React, { FC } from "react";
import Blocks from "components/Blocks";
import AppLayout from "layouts/AppLayout";

const Home: FC = () => {
  return (
    <AppLayout>
      <section className="first-screen"></section>
      <Blocks />
    </AppLayout>
  );
};

export default Home;
