import React, { FC } from "react";

import styles from "./index.module.scss";

import { useAppSelector } from "store/hooks";
import { getSettings, getCareerTabData } from "store/slices";
import SuggestionsBlock from "components/SuggestionsBlock";
import VideoBlock from "../VideoBlock";
import DailySchedule from "../DailySchedule";
import Occupations from "../Occupations";
import Comments from "../Comments";
import PrimaryButton from "components/common/Button/PrimaryButton";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import { accent400 } from "constantVariables";

const CareerMobile: FC = () => {
  const { dir, language } = useAppSelector(getSettings);
  const { activeCareer } = useAppSelector(getCareerTabData);
  const { tabHandler, lifeChoice } = useLifeChoicePageContext();
  if (!dir || !language) return null;

  return (
    <div className={`${styles["career-mobile-wrapper"]}`}>
      {activeCareer && activeCareer.videoUrl && (
        <div className={`container`}>
          <VideoBlock videoUrl={activeCareer.videoUrl} />
        </div>
      )}
      <Occupations />

      {activeCareer && activeCareer.calendar && (
        <div
          className={`${styles["career-mobile-schedule"]} container career-mobile-schedule`}
        >
          <DailySchedule />
        </div>
      )}

      {activeCareer && activeCareer.reviews && (
        <div className={styles["career-mobile-comments"]}>
          <p dir={dir} className={styles["career-mobile-comments__tab"]}></p>
          <Comments title={activeCareer.title} items={activeCareer.reviews} />
          <div className={styles["career-mobile-apply"]}>
            <p className={styles["career-mobile-apply-text"]}>Think this is the right path for you?</p>
            <PrimaryButton
              color={accent400}
              onClick={() => tabHandler("apply")}
              className={styles["career-mobile-apply-btn"]}
            >
              {lifeChoice.translationTexts.applyBtn[language]}
            </PrimaryButton>
          </div>
        </div>
      )}

      <SuggestionsBlock />
    </div>
  );
};

export default CareerMobile;
