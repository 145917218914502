import React, { FC } from "react";
import { Rating } from "react-simple-star-rating";
import styles from "./index.module.scss";
import { IReviewCardProps } from "types/components/common/reviewCard";
import { lifeChoiseDetailsDesktop, ratingColors } from "constantVariables";
import Image from "components/common/Image";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import ReadMore from "components/ReadMore";
import useMediaQuery from "hooks/useMediaQuery";

const ReviewCard: FC<IReviewCardProps> = ({
  className,
  item: { author, content, imageUrl, numOfStars, graduateYear, company },
  itemType = "primary",
  title,
  cardType,
}) => {
  const { dir, language } = useAppSelector(getSettings);
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  return (
    <div
      className={`${styles["review-card"]} ${
        className ? styles[className] : ""
      }`}
      itemType={itemType || "primary"}
      dir={dir}
      lang={language}
    >
      <div className={styles["user-info"]}>
        <Image src={imageUrl || ""} />
        <h3 className={`${styles["author"]}`}>{author}</h3>

        {cardType === "careerCard" && (
          <h5
            lang={language}
            dir={dir}
            className={`${
              isDesktop
                ? styles["career-card-company"]
                : styles["career-card-company-mob"]
            }`}
          >
            {title && title[language]} | {company}
          </h5>
        )}

        {cardType === "overviewCard" && (
          <h5 className={`${styles["graduate-year"]}`}>
            Graduated {graduateYear}
          </h5>
        )}
      </div>
      <p className={`${styles["content"]} | margin-block-start-200`}>
        <ReadMore charactersToPreview={150} lang={language} dir={dir}>
          {content}
        </ReadMore>
      </p>
      <Rating
        ratingValue={(numOfStars * 100) / 5}
        readonly
        fillColor={ratingColors[itemType].fillColor}
        emptyColor={ratingColors[itemType].emptyColor}
        size={25}
        rtl={dir === "rtl"}
      />
    </div>
  );
};

export default ReviewCard;
