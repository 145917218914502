import ImageCard from "components/common/Card/ImageCard";
import Carousel from "components/common/Carousel";
import React, { FC, useCallback } from "react";
import styles from "./index.module.scss";
import academyMock from "mocks/academy_example.json";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";

const FacultyPhotos: FC = () => {
  const { dir, language } = useAppSelector(getSettings);
  const { lifeChoice } = useLifeChoicePageContext();

  const renderItem = useCallback((item: string) => {
    return <ImageCard src={item} />;
  }, []);

  return (
    <div
      className={`${styles["faculty-photos"]} | container`}
      dir={dir}
      lang={language}>
      <h4 className={`${styles["title"]} | fw-600 text-primary-400`}>
        {lifeChoice?.translationTexts?.facoltyPhotos?.[language]}
      </h4>
      <Carousel items={academyMock?.images} renderItem={renderItem} />
    </div>
  );
};

export default FacultyPhotos;
