import React, { FC } from "react";
import { IIconProps } from "types/components/icon";

const StudentHatIcon: FC<IIconProps> = ({ fill, size }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size || 16}
      height={size || 16}
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      fill={fill || "#fff"}>
      <g>
        <g>
          <path
            d="M7.279,15.649v7.466c0,0,2.706-0.939,5.351-0.027s7.112,3.602,7.112,3.602s1.367-2.223,2.964-3.25
			c1.596-1.027,2.826-1.814,2.826-1.814v-5.772l-4.925,4.349L7.279,15.649z"
          />
          <polygon
            points="3.573,13.681 20.438,19.088 31.17,9.507 13.718,4.48 0,12.536 2.176,13.232 2.176,19.356 1.209,19.356 
			1.209,23.438 4.593,23.438 4.593,19.356 3.573,19.356 		"
          />
        </g>
      </g>
    </svg>
  );
};

export default StudentHatIcon;
