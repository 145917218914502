import ChevronDownIcon from "assets/icons/ChevronDownIcon";
import ChevronUpIcon from "assets/icons/ChevronUpIcon";
import React, { PropsWithChildren, useState } from "react";
import { IAccordionProps } from "types/components/common/accordion";
import IconButton from "../Button/IconButton";
import styles from "./index.module.scss";

const AccordionItem = <T,>({
  renderBody,
  renderHeader,
  headerClassName,
  hideDropdownbtn,
  item,
}: PropsWithChildren<IAccordionProps<T>>) => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <div className={`${styles["accordion-item"]}`}>
      <div
        className={`${styles["accordion-header"]} ${headerClassName || ""}`}
        onClick={() => setActive(!active)}>
        {renderHeader(item)}{" "}
        {!hideDropdownbtn && (
          <IconButton
            icon={
              active ? (
                <ChevronUpIcon fill="#5a70e8" />
              ) : (
                <ChevronDownIcon fill="#5a70e8" />
              )
            }
          />
        )}
      </div>
      <div className={styles["accordion-body"]} {...(active && {'aria-expanded': true})}>
        {renderBody(item)}
      </div>
    </div>
  );
};

export default AccordionItem;
