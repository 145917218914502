import React, { FC } from "react";
import { ISearchInputProps } from "types/components/common/inputSearch";
import styles from "./index.module.scss";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const SearchInput: FC<ISearchInputProps> = ({
  icon,
  inputClassName,
  inputContainerClassName,
  ...rest
}) => {
  const { dir } = useAppSelector(getSettings);

  return (
    <div
      className={`${
        dir === "rtl" ? styles["search-input-container-rtl"] : ""
      } ${styles["search-input-container"]} ${inputContainerClassName || ""}`}
    >
      {icon && (
        <div
          className={`${dir === "rtl" ? styles["input-icon-rtl"] : ""} ${
            styles["input-icon"]
          } | d-flex align-center`}
        >
          {icon}
        </div>
      )}
      <input
        dir={dir}
        key="search-input"
        type="text"
        className={`${styles["search-input"]} ${inputClassName || ""}`}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
