import React, { FC } from "react";
import { IButtonProps } from "types/components/common/btn";
import { primary300 } from "constantVariables";
import styles from "./index.module.scss";

const PrimaryButton: FC<IButtonProps> = ({ children, color, className, ...rest }) => {
  return (
    <button
      className={`${styles["primary-btn"]} ${className || ""}`}
      {...rest}
      style={{ backgroundColor: color || primary300 }}>
      {children}
    </button>
  );
};

export default PrimaryButton;
