import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IDefaultCardProps } from "types/components/common/defaultCard";
import styles from "./index.module.scss";

const DefaultCard: FC<IDefaultCardProps> = ({ children, className }) => {
  const { dir, language } = useAppSelector(getSettings);

  return (
    <div
      className={`${styles["default-card"]} ${className || ""}`}
      dir={dir}
      lang={language}>
      {children}
    </div>
  );
};

export default DefaultCard;
