import React, { FC } from "react";

import styles from "./index.module.scss";

import { useAppSelector } from "store/hooks";
import { getCareerTabData, getSettings } from "store/slices";
import SuggestionsBlock from "components/SuggestionsBlock";
import Comments from "../Comments";
import VideoBlock from "../VideoBlock";
import DailySchedule from "../DailySchedule";
import Occupations from "../Occupations";

const CareerDesktop: FC = () => {
  const { dir, language } = useAppSelector(getSettings);
  const { activeCareer } = useAppSelector(getCareerTabData);
  if (!dir || !language) return null;

  return (
    <div className={`${styles["career-desktop-wrapper"]} | container`}>
      <Occupations />
      <div className={`${styles["career-desktop-top"]}`}>
        {activeCareer && activeCareer.videoUrl && (
          <div className={styles["career-desktop-video"]}>
            <VideoBlock videoUrl={activeCareer.videoUrl} />
          </div>
        )}

        {activeCareer && activeCareer.videoUrl && (
          <div
            className={`${styles["career-desktop-schedule"]}  career-desktop-schedule`}
          >
            <DailySchedule />
          </div>
        )}
      </div>

      {activeCareer && activeCareer.reviews && (
        <Comments title={activeCareer.title} items={activeCareer.reviews} />
      )}

      <SuggestionsBlock />
    </div>
  );
};

export default CareerDesktop;
