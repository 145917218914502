import React, { FC } from "react";
import styles from "./index.module.scss";
import ApplyNow from "components/ApplyNow";
import SuggestionsBlock from "components/SuggestionsBlock";
import QnA from "../Q&A";
import CourseList from "../CourseList";
import FacultyPhotos from "../FacultyPhotos";
import { getSettings } from "store/slices";
import { useAppSelector } from "store/hooks";

const AcademyDesktop: FC = () => {
  const { dir } = useAppSelector(getSettings);

  return (
    <section className={styles["academy-wrapper"]}>
      <div dir={dir} className={`${styles["academy-top-block"]} | container`}>
        <div className={styles["academy-course-list"]}>
          <CourseList />
        </div>
        <div className={styles["academy-questions"]}>
          <QnA />
        </div>
      </div>
      <FacultyPhotos />
      <ApplyNow />
      <SuggestionsBlock />
    </section>
  );
};

export default AcademyDesktop;
