/*eslint-disable*/
import React, { FC, useMemo, useState } from "react";
import styles from "./index.module.scss";
import Carousel from "components/common/Carousel";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import useMediaQuery from "hooks/useMediaQuery";
import {
  lifeChoiseDetailsDesktop,
  lifeChoiseDetailsMobile,
  lifeChoiseDetailsTablet,
} from "constantVariables";
import { ILifeChoiceMajorCareer } from "types/lifeChoice";
import Occupation from "../Occupation";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getSettings,
  getCareerTabData,
  setActiveCareer,
  setActiveCareersSliders,
} from "store/slices";

const Occupations: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);
  const isMobile = useMediaQuery(lifeChoiseDetailsMobile);
  const isTablet = useMediaQuery(lifeChoiseDetailsTablet);
  const { language, dir } = useAppSelector(getSettings);
  const dispatch = useAppDispatch();

  const [occupations, setOccupations] = useState<ILifeChoiceMajorCareer[][]>([
    lifeChoice.careers,
  ]);

  const carouselOptions = useMemo(() => {
    let options = {
      slidesPerView: 5,
      slideToClickedSlide: true,
      centeredSlides: true,
    };
    if (isDesktop) {
      options = {
        ...options,
        slidesPerView: 5,
      };
    } else if (isTablet) {
      options = {
        ...options,
        slidesPerView: 3,
      };
    } else if (isMobile) {
      options = {
        ...options,
        slidesPerView: 2,
      };
    }
    return options;
  }, [isDesktop, isMobile, isTablet]);
  
  const renderItem = (item: ILifeChoiceMajorCareer, idx?: number) => {
    const onClick = async () => {

      dispatch(setActiveCareer(item));

      const newActiveSLider = {
        [idx!]: item.discripetion,
      };
      dispatch(setActiveCareersSliders(newActiveSLider));

      if (!idx) idx = 0;

      let newArr = [...occupations];

      if (!item.occupations.length)
        return setOccupations([...newArr.filter((_, iIdx) => iIdx <= idx!)]);

      newArr = newArr.filter((_, iIdx) => iIdx <= idx!);

      if (typeof item.occupations[0] === "number") {
        let arr = [];

        for await (let occup of item.occupations) {
          const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}api/v1/career/${occup}`,
            {
              headers: {
                tocken: "q8np39yq849356voq45vo47856voq6b5oqb45v6oqb65",
              },
            }
          );
          const data = await res.json();

          if (data?.status === "FAILED") continue;

          arr.push(data);
        }

        if (!arr.length)
          return setOccupations([...newArr.filter((_, iIdx) => iIdx <= idx!)]);

        setOccupations([...newArr, arr]);

        return;
      }

      setOccupations([...newArr, item.occupations as ILifeChoiceMajorCareer[]]);
    };

    return <Occupation idx={idx} item={item} onClick={onClick} />;
  };

  return (
    <div className="occupations">
      <p className={styles["occupations-title"]}>
        Occupation funnel for{" "}
        <h1 lang={language} dir={dir} className="p-block-1">
          {lifeChoice.title[language]}
        </h1>
      </p>
      {occupations.map((occupation, idx) => {
        return (
          <div className={`${styles["occupations-carousel"]}`}>
            <p className={styles["occupations-sub-title"]}>
              {idx === 0 ? "Job description" : ""}
              {idx === 1 ? "Choose type" : ""}
            </p>
            <Carousel
              key={`--carousel-occupation-${idx}`}
              items={occupation}
              renderItem={renderItem}
              idx={idx}
              options={
                occupation.length < carouselOptions.slidesPerView!
                  ? {
                      ...carouselOptions,
                      loop: false,
                    }
                  : carouselOptions
              }
            />
          </div>
        );
      })}
      <div className={styles["occupations-more-wrapper"]}>
        <p className={styles["occupations-more-triangle"]}></p>
      </div>
    </div>
  );
};

export default Occupations;
