import Hashtags from "components/Hashtags";
import SuggestionsBlock from "components/SuggestionsBlock";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import CareerInfo from "../CareerInfo";
import Comments from "../Comments";
import GeneralSkills from "../GeneralSkills";
import HighlightningBenefits from "../HiglightningBenefits";
import styles from "../index.module.scss";
import LifeChoiceDescription from "../LifeChoiceDescription";
import Stats from "../Stats";
import TechnicalSkills from "../TechnicalSkills";
import CareerInfoContent from "../CareerInfoContent";
import SalleryDistribution from "../SalleryDistribution";
import JobFields from "../JobFields";

const OverviewMobile: FC = () => {
  const {
    lifeChoice: {
      majorOverview: { hashtags },
    },
  } = useLifeChoicePageContext();

  return (
    <section className={styles["overview-mobile-wrapper"]}>
      <Hashtags hashtags={hashtags} className={styles["overview-hashtags-mobile"]}/>
      <LifeChoiceDescription />
      <HighlightningBenefits />
      <Stats />
      <Comments />
      <GeneralSkills />
      <TechnicalSkills />
      <CareerInfo>
        <CareerInfoContent />
        <SalleryDistribution />
        <JobFields />
      </CareerInfo>
      <SuggestionsBlock />
    </section>
  );
};

export default OverviewMobile;
