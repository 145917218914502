import DefaultCard from "components/common/Card/DefaultCard";
import Progress from "components/common/Progress";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import styles from "../index.module.scss";

const Stats: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);

  if (!language || !lifeChoice) return null;

  return (
    <div className="container">
      <DefaultCard className={`${styles["common-stats-card"]} | container`}>
        {lifeChoice.majorOverview.commonStats.map(({ title, counter }, idx) => (
          <div
            className={styles["stat-row"]}
            key={`--stat-row-${title}-${idx}`}>
            <span>{title[language]}</span>
            <Progress
              className={styles["common-stats-progress"]}
              max={counter.max}
              value={counter.value}
            />
            <div dir={dir} className={styles["common-stats-count"]}>{counter.value}</div>
          </div>
        ))}
      </DefaultCard>
    </div>
  );
};

export default Stats;
