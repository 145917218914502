import OutlinedButton from "components/common/Button/OutlinedButton";
import React, { FC, useState } from "react";
import { IReadMoreProps } from "types/components/readMore";
import styles from "./index.module.scss";

const ReadMore: FC<IReadMoreProps> = ({
  children,
  charactersToPreview = 150,
  dir = "ltr",
  lang = "en",
  className,
}) => {
  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <p dir={dir} lang={lang} className={className || ""}>
      {children.length > 150 ?
        (hidden ? (
          <>
            {children.slice(0, charactersToPreview)}...{" "}
            <OutlinedButton
              className={styles["read-more-btn"]}
              color="#1a4e99"
              onClick={() => setHidden(!hidden)}
            >
              
              {lang==="en" ? "Read More" : "ראה עוד"}

            </OutlinedButton>
          </>
        ) : (
          <>
            {children}{" "}
            <OutlinedButton
              className={styles["read-more-btn"]}
              color="#1a4e99"
              onClick={() => setHidden(!hidden)}
            >
              {lang==="en" ? "Hide" : "צמצם"}
            </OutlinedButton>
          </>
        )) : children
        }
    </p>
  );
};

export default ReadMore;
