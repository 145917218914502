import PrimaryButton from "components/common/Button/PrimaryButton";
import { accent400, primary300 } from "constantVariables";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import styles from "./index.module.scss";

const ApplyNow: FC = () => {
  const { tabHandler, lifeChoice } = useLifeChoicePageContext();
  const { language } = useAppSelector(getSettings);

  return (
    <div className={`${styles["apply-now"]} | container`}>
      <PrimaryButton color={primary300} onClick={() => tabHandler("apply")}>
        {lifeChoice?.academy?.translationTexts?.learnMore?.[language]}
      </PrimaryButton>
      <PrimaryButton color={accent400} onClick={() => tabHandler("apply")}>
        {lifeChoice?.academy?.translationTexts?.applyBtn?.[language]}
      </PrimaryButton>
    </div>
  );
};

export default ApplyNow;
