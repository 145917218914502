import Modal from "components/common/Modal";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import {
  IModalContext,
  IModalProviderProps,
  IModalSize,
} from "types/context/modal";

export const ModalContext = createContext({} as IModalContext);

const ModalProvider: FC<IModalProviderProps> = ({ children }) => {
  const [modalContent, setModal] = useState<ReactNode>(null);
  const [modalSize, setModalSize] = useState<IModalSize>("lg");

  const showModal = useCallback(
    (content: ReactNode, size?: IModalSize) => {
      setModal(content);
      if (size) setModalSize(size);
    },
    [setModal]
  );

  const hideModal = useCallback(() => {
    setModal(null);
    setModalSize("lg");
  }, []);

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal, open: !!modalContent, modalSize }}>
      {children}
      <Modal>{modalContent}</Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
