import ScheduleCard from "components/common/Card/ScheduleCard";
import Carousel from "components/common/Carousel";
import React from "react";
import { IScheludeItem } from "types/lifeChoice";
import styles from "./index.module.scss";
import { useAppSelector } from "store/hooks";
import { getCareerTabData, getSettings } from "store/slices";

/*eslint-disable*/
const DailySchedule = () => {
  const { activeCareer } = useAppSelector(getCareerTabData);
  const { dir } = useAppSelector(getSettings);

  let calendarOneDaySchedule = [];
  if (activeCareer?.calendar) {
    const calendarOneDay = Object.values(activeCareer.calendar);
    calendarOneDaySchedule = calendarOneDay.map((item) => item.events);
  }

  const carouselOptions = {
    slidesPerView: 1,
    pagination: { clickable: true },
  };

  const renderItem = (item: IScheludeItem) => <ScheduleCard item={item} />;

  return (
    <div className={styles["schedule"]}>
      <div className={styles["schedule-top-wrapper"]} dir={dir}>
        <h2 className={styles["schedule-title"]}>Daily Schedule</h2>
      </div>
      <Carousel
        items={calendarOneDaySchedule}
        renderItem={renderItem}
        options={carouselOptions}
        sliderDailyScheduleBtns={true}
      />
    </div>
  );
};

export default DailySchedule;
