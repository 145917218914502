/*eslint-disable*/

import OccupationCard from "components/common/Card/OccupationCard";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getCareerTabData } from "store/slices";
import { useSwiperSlide, useSwiper } from "swiper/react";
import { IOccupationProps } from "types/components/occupation";
import styles from "./index.module.scss";

const Occupation: FC<IOccupationProps> = ({ item, onClick, idx }) => {
  const { isActive } = useSwiperSlide();
  const { activeCareersSliders } = useAppSelector(getCareerTabData);
  const [hasDescription, setHasDescription] = useState(false);

 useEffect(() => {
    if (activeCareersSliders && idx!==undefined) {
      if (activeCareersSliders[idx]) {
        setHasDescription(true);
      } else {
        setHasDescription(false);
      }
    }
  }, [activeCareersSliders,idx]);

  const subCarousel = !!(idx!==undefined && idx>0);
  
  const focusedCarousel = isActive && hasDescription

  let isOrange;
  if((idx!==undefined) && activeCareersSliders) {
    isOrange = isActive && (idx<parseInt(Object.keys(activeCareersSliders)[0]))
  }

  return (
    <OccupationCard
      item={item}
      className={`${ focusedCarousel ? (subCarousel ?  styles["occupation-focused-blue"] : styles["occupation-focused"]): ""}`}
      onClick={onClick}
      showDescription={isActive && hasDescription}
      subCarousel={subCarousel}
      isOrange={isOrange}
    />
  );
};

export default Occupation;
