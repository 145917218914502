import Hashtags from "components/Hashtags";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import CareerInfo from "../CareerInfo";
import CareerInfoContent from "../CareerInfoContent";
import JobFields from "../JobFields";
import SalleryDistribution from "../SalleryDistribution";
import Comments from "../Comments";
import styles from "./index.module.scss";
import HighlightningBenefits from "../HiglightningBenefits";
import Stats from "../Stats";
import PrimaryButton from "components/common/Button/PrimaryButton";
import { accent400 } from "constantVariables";
import GeneralSkills from "../GeneralSkills";
import TechnicalSkills from "../TechnicalSkills";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import SuggestionsBlock from "components/SuggestionsBlock";
import { useTranslation } from "react-i18next";

const OverviewDesktop: FC = () => {
  const {
    lifeChoice: {
      majorOverview: { hashtags, description },
    },
    tabHandler,
  } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);
  const { t } = useTranslation();

  if (!dir || !language) return null;

  return (
    <div
      className={`${styles["overview-desktop-wrapper"]} | container`}>
      <div className={styles["sides"]}>
        <div className={styles["left-side"]}>
          <Hashtags
            hashtags={hashtags}
            className={styles["hashtags-overview"]}
          />
          <p dir={dir} lang={language} className="p-inline-1">
            {description[language]}
          </p>
          <div>
            <Comments />
          </div>
          <CareerInfo>
            <CareerInfoContent />
            <JobFields />
            <SalleryDistribution />
          </CareerInfo>
        </div>
        <div className={styles["right-side"]}>
          <HighlightningBenefits />
          <Stats />
          <PrimaryButton
            className="m-inline-1"
            color={accent400}
            onClick={() => tabHandler("apply")}>
            {t("applyNow")}
          </PrimaryButton>
          <GeneralSkills />
          <TechnicalSkills />
        </div>
      </div>
      <SuggestionsBlock />
    </div>
  );
};

export default OverviewDesktop;
