import React from "react";
import { ILifeChoice } from "types/lifeChoice";

export interface ILifeChoicePageContext {
  activeTab: ILifeChoiceTabsType;
  tabHandler: (tab: ILifeChoiceTabsType) => void;
  lifeChoice: ILifeChoice;
}

export interface ILifeChoiceProviderProps {
  children: React.ReactNode;
}

export type ILifeChoiceTabsType = "overview" | "career" | "academy" | "apply";

export enum ILifeChoiceTabs {
  OVERVIEW = "overview",
  CAREER = "career",
  ACADEMY = "academy",
  APPLY = "apply",
}
