export const numArrToChart = (arr: number[], yAxisCoefficient: number = 1 as number) => {
  let yAxisVal = 0;

  const median = arr[Math.round((arr.length - 1) / 2)];

  return arr.map((val) => {
    if (val > median) {
      yAxisVal -= yAxisCoefficient;

      return {
        val1: yAxisVal > 0 ? yAxisVal - yAxisCoefficient : 0,
        val2: val,
      };
    }

    const obj = {
      val1: yAxisVal,
      val2: val,
    };

    yAxisVal += yAxisCoefficient;

    return obj;
  });
};
