import React, { useCallback } from "react";
import styles from "./index.module.scss";
import academyMock from "mocks/academy_example.json";
import AccordionItem from "components/common/AccordionItem";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IQnA } from "types/lifeChoice";
import ReadMore from "components/ReadMore";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";

const QnA = () => {
  const { dir, language } = useAppSelector(getSettings);
  const { lifeChoice } = useLifeChoicePageContext();

  const renderHeader = useCallback(
    ({ question }: IQnA) => (
      <h4 className="fw-500 text-primary-300">{question[language]}</h4>
    ),
    [language]
  );
  const renderBody = useCallback(
    ({ answer }: IQnA) => (
      <ReadMore
        charactersToPreview={150}
        dir={dir}
        lang={language}
        className="fw-500 text-primary-300"
      >
        {answer[language]}
      </ReadMore>
    ),
    [language]
  );

  return (
    <div className={`${styles["qna"]} | container`} dir={dir} lang={language}>
      <h4 className={`${styles["title"]} | fw-600 text-primary-400`}>
        {lifeChoice?.translationTexts?.qnaText?.[language]}
      </h4>
      <div className={styles["accordion-list"]}>
        {academyMock.qna.map((item, idx) => (
          <AccordionItem
            renderBody={renderBody}
            renderHeader={renderHeader}
            hideDropdownbtn
            key={idx}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default QnA;
