import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {defaultSlice, careerTabSlice} from "store/slices";
import { lifeChoiseBlocksApi } from "./api/LifeChoiceBlocks";

export const store = configureStore({
  reducer: {
    [lifeChoiseBlocksApi.reducerPath]: lifeChoiseBlocksApi.reducer,
    settings: defaultSlice.reducer,
    careerTab: careerTabSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(lifeChoiseBlocksApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
