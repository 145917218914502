import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ISettings } from "types/store/settings";
import { ICareerTab } from "types/store/careerTab";

const initialState: ISettings = {
  language: "en",
  dir: "ltr",
  isLoading: false,
  languages: [
    {
      lang: "en",
      dir: "ltr",
    },
    {
      lang: "he",
      dir: "rtl",
    },
  ],
};

const initialStateCareerTabSlice: ICareerTab = {
  sliderClickedArray: null,
  activeCareer: null,
  activeCareersSliders: null,
};


export const defaultSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.language = payload.lang;
      state.dir = payload.dir;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

  },
});

export const careerTabSlice = createSlice({
  name: "careerTab",
  initialState: initialStateCareerTabSlice,
  reducers: {
    setActiveCareer: (state, { payload }) => {
      state.activeCareer = payload;
    },
    setActiveCareersSliders: (state, { payload }) => {
      state.activeCareersSliders = payload;
    },
  },
});

export const getSettings = (state: RootState) => state.settings;
export const getCareerTabData = (state: RootState) => state.careerTab;

export const { setLanguage, setLoading } = defaultSlice.actions;
export const { setActiveCareer, setActiveCareersSliders } = careerTabSlice.actions;

