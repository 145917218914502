import ReadMore from "components/ReadMore";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const LifeChoiceDescription: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);

  if (!language || !lifeChoice) return null

  return (
    <div className="life-choice-description | container">
      <ReadMore dir={dir} lang={language} charactersToPreview={260}>
        {lifeChoice?.majorOverview.description[language]}
      </ReadMore>
    </div>
  );
};

export default LifeChoiceDescription;
