import AccordionList from "components/common/AccordionList";
import Progress from "components/common/Progress";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import React, { FC, useCallback } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IGeneralSkill } from "types/lifeChoice";
import styles from "../index.module.scss";

const GeneralSkills: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { dir, language } = useAppSelector(getSettings);

  const renderHeader = useCallback(
    ({ title, value }: IGeneralSkill) => (
      <>
        <h5>{title[language]}</h5>
        <Progress max={100} value={value} />
      </>
    ),
    [dir, language]
  );

  const renderBody = useCallback(
    ({ description }: IGeneralSkill) => <p>{description[language]}</p>,
    [dir, language]
  );

  return (
    <div
      className={`${styles["general-skills"]} | container`}
      dir={dir}
      lang={language}
    >
      <h4 className="fw-500 title-primary" lang={language} dir={dir}>
        {lifeChoice.majorOverview.generalSkills.section[language]}:
      </h4>
      <AccordionList
        items={lifeChoice.majorOverview.generalSkills.skills}
        renderHeader={renderHeader}
        renderBody={renderBody}
        headerClassName={styles["accordion-header-custom"]}
      />
    </div>
  );
};

export default GeneralSkills;
