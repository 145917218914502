import React, { FC } from "react";

import styles from "./index.module.scss";

import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import { IVideoBlockProps } from "types/components/videoBlock";
import useMediaQuery from "hooks/useMediaQuery";
import { lifeChoiseDetailsDesktop } from "constantVariables";

const VideoBlock: FC<IVideoBlockProps> = ({ videoUrl }) => {
  const { dir, language } = useAppSelector(getSettings);
  if (!dir || !language) return null;
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);

  return (
    <div className={isDesktop ? "" : styles["career-video-mob"]}>
      <h2 className={styles["career-video-title"]}>
        <span
          className={`${
            dir === "rtl" ? styles["career-video-title__triangle-rtl"] : ""
          } ${styles["career-video-title__triangle"]}`}
        ></span>
        <span>An avarage working day</span>
      </h2>
      <div className={styles["career-video-wrapper"]}>
        <iframe
          width="100%"
          height="300"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoBlock;
