import Image from "components/common/Image";
import { useLifeChoicePageContext } from "context/hooks/useLifeChoicePageContext";
import { useModal } from "context/hooks/useModal";
import React, { FC } from "react";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";
import styles from "../index.module.scss";
import TechnicalSkillModal from "./TechnicalSkillModal";

const TechnicalSkills: FC = () => {
  const { lifeChoice } = useLifeChoicePageContext();
  const { showModal } = useModal();
  const { dir, language } = useAppSelector(getSettings);

  return (
    <div
      className={`${styles["technical-skills"]} | container`}
      dir={dir}
      lang={language}
    >
      <h4 className="fw-500 title-primary" lang={language} dir={dir}>
        {lifeChoice.majorOverview.technicalSkills.section[language]}
      </h4>
      <ul
        className="grid"
        role="list"
        style={{ "--cols": 3, "--cols-mobile": 3 } as any}
      >
        {lifeChoice.majorOverview.technicalSkills.skills.map((skill, idx) => (
          <li
            key={`--technical-skill-${idx}`}
            className={styles["technical-skill"]}
            onClick={() =>
              showModal(<TechnicalSkillModal skill={skill} />, "sm")
            }
          >
            <Image src={skill.logoUrl} errorMessage={"Something went wrong!"} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TechnicalSkills;
