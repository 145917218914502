import React, { FC } from "react";
import { IBlockCardProps } from "types/components/common/blockCard";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import Image from "components/common/Image";
import { useAppSelector } from "store/hooks";
import { getSettings } from "store/slices";

const BlockCard: FC<IBlockCardProps> = ({
  item: { title, imagePath, id },
  ariaCardSize,
}) => {
  const navigate = useNavigate();
  const { language } = useAppSelector(getSettings);

  return (
    <div
      className={styles["block-card-wrapper"]}
      onClick={() => navigate(`/${id}`)}
      {...(ariaCardSize && { "aria-card-size": ariaCardSize })}>
      <div className={styles["block-card__title"]}>
        <h1>{title[language] ?? title}</h1>
      </div>
      <Image
        alt="card-img"
        src={imagePath}
        className={styles["block-card-img"]}
      />
    </div>
  );
};

export default BlockCard;
