import { lifeChoiseDetailsDesktop } from "constantVariables";
import useMediaQuery from "hooks/useMediaQuery";
import React, { FC } from "react";
import OverviewDesktop from "./Desktop";
import OverviewMobile from "./Mobile";

const Overview: FC = () => {
  const isDesktop = useMediaQuery(lifeChoiseDetailsDesktop);

  return <>{!isDesktop ? <OverviewMobile /> : <OverviewDesktop />}</>;
};

export default Overview;
